import React from 'react';
import styled from 'styled-components';

const HomeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const HomeContainer = styled.div`
  position: relative;
  text-align: center;
  opacity: 1; /* Adjust transparency level (0 is fully transparent, 1 is fully opaque) */
  overflow: hidden; /* Ensure pseudo-elements don't overflow */
`;

const OverlayText = styled.div`
  position: relative;
  z-index: 1; /* Ensure text is above the overlay */
  max-width: 90vw; /* Ensure text doesn't overflow horizontally */
  margin: 0 auto; /* Center align text horizontally */

  h1, p {
    background: linear-gradient(45deg, rgba(255, 87, 34, 1), rgba(0, 0, 0, 0.8)); /* Orange to red gradient with opacity */
    -webkit-background-clip: text; /* Clip background to text */
    -webkit-text-fill-color: transparent; /* Make text color transparent to show the gradient */
    white-space: nowrap; /* Prevent text from wrapping */
  }

  h1 {
    font-size: clamp(2rem, 5vw, 4rem); /* Responsive font size with a minimum and maximum value */
    font-family: 'League Spartan', sans-serif; /* Custom font */
  }

  p {
    font-size: clamp(1rem, 2.5vw, 1.5rem); /* Responsive font size with a minimum and maximum value */
    font-family: 'Roboto', sans-serif; /* Custom font */
  }
`;

const Home = () => {
  return (
    <HomeWrapper>
      <HomeContainer>
        <OverlayText>
          <h1>Welcome to iClean</h1>
          <p>Where Cleanliness Begins</p>
        </OverlayText>
      </HomeContainer>
    </HomeWrapper>
  );
};

export default Home;
