import React from 'react';
import styled from 'styled-components';

const ContactContainer = styled.div`
  padding: 60px 20px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  color: #111;
  margin: 40px auto;
  max-width: 800px;
  text-align: center;
  animation: fadeIn 1s ease-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #333;
  font-family: 'League Spartan', sans-serif;
`;

const Subtitle = styled.h2`
  font-size: 1.75rem;
  margin: 2rem 0 1rem;
  color: #555;
  font-family: 'League Spartan', sans-serif;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  font-family: 'League Spartan', sans-serif;
`;

const ContactButton = styled.a`
  display: inline-block;
  padding: 12px 24px;
  font-size: 1rem;
  color: #fff;
  background: linear-gradient(45deg, #ff5722, #ff1744);
  border-radius: 30px;
  text-decoration: none;
  transition: transform 0.3s, box-shadow 0.3s;
  margin: 10px;
  border: 1px solid transparent;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 87, 34, 0.5);
  }
`;

const Contact = () => {
  return (
    <ContactContainer>
      <Title>Contact Us</Title>
      <Paragraph>If you have any questions, feel free to reach out!</Paragraph>
      <Subtitle>Contact Information</Subtitle>
      <Paragraph>
        <strong>Phone:</strong> <ContactButton href="tel:+916291230617">+91-6291230617</ContactButton>
      </Paragraph>
      <Paragraph>
        <strong>WhatsApp:</strong> <ContactButton href="https://wa.me/916291230617">Send Message</ContactButton>
      </Paragraph>
      <Paragraph>
        <strong>Email:</strong> <ContactButton href="mailto:helpline.iclean@gmail.com">helpline.iclean@gmail.com</ContactButton>
      </Paragraph>
    </ContactContainer>
  );
};

export default Contact;
