import React from 'react';
import ProductList from '../components/ProductList';
import styled from 'styled-components';

const ProductsContainer = styled.div`
  padding: 40px;
   background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  border-radius: 10px; /* Optional: rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow */
margin: auto;
  color: black;

   h1 {
    background: linear-gradient(45deg, rgba(255, 87, 34, 1), rgba(0, 0, 0, 0.8)); /* Orange to red gradient with opacity */
    -webkit-background-clip: text; /* Clip background to text */
    -webkit-text-fill-color: transparent; /* Make text color transparent to show the gradient */
    white-space: nowrap; /* Prevent text from wrapping */
  }
`;

const Products = () => {
  return (
    <ProductsContainer>
      <h1>Our Products</h1>
      <ProductList />
    </ProductsContainer>
  );
};

export default Products;
