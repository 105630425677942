import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaStar, FaShieldAlt, FaRegSmile, FaLeaf, FaSprayCan } from 'react-icons/fa';
import { Link } from 'react-router-dom';

// Keyframes for button hover effect
const buttonHover = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const PageContainer = styled.div`
  padding: 40px 20px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #111;
  max-width: 800px;
  margin: 40px auto;
  text-align: center;
  animation: ${fadeIn} 1s ease-out;
`;

const Header = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
  font-family: 'League Spartan', sans-serif;
`;

const SectionTitle = styled.h2`
  font-size: 1.75rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #333;
  font-family: 'League Spartan', sans-serif;
`;

const Tagline = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #555;
  font-family: 'League Spartan', sans-serif;
`;

const CTAButton = styled(Link)`  // Changed to Link for navigation functionality
  background: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-family: 'League Spartan', sans-serif;
  cursor: pointer;
  text-decoration: none;
  transition: background 0.3s, transform 0.3s;
  margin: 0 auto;
  display: inline-block;  // Ensures button does not stretch
  max-width: 200px;  // Prevents stretching

  &:hover {
    background: #45A049;
    transform: scale(1.05);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }
`;

const ProductImage = styled.img`
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
`;

const TestimonialsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  text-align: center;
`;

const Testimonial = styled.div`
  width: 30%;
  font-size: 1rem;
  color: #555;
  font-family: 'League Spartan', sans-serif;
`;

const TestimonialText = styled.p`
  margin: 0.5rem 0;
`;

const Stars = styled.div`
  color: gold;
`;

const FeaturesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
`;

const Feature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 150px;
`;

const FeatureIcon = styled.div`
  font-size: 4rem;
  color: #4CAF50;
  margin-bottom: 0.5rem;
`;

const FeatureText = styled.p`
  font-size: 1rem;
  margin-top: 0.5rem;
  color: #333;
  font-family: 'League Spartan', sans-serif;
  text-align: center;
`;

const Instructions = styled.div`
  text-align: left;
  margin-bottom: 2rem;
`;

const FAQContainer = styled.div`
  margin-top: 2rem;
  text-align: left;
`;

const FAQTitle = styled.h2`
  font-size: 1.75rem;
  margin-bottom: 1rem;
  color: #333;
  font-family: 'League Spartan', sans-serif;
`;

const BleachingPowderPage = () => {
  return (
    <PageContainer>
      <Header>iClean Bleaching Powder</Header>
      <Tagline>Effective and powerful bleaching solution for all your cleaning needs</Tagline>
      {/* <CTAButton to="/products">Get Started</CTAButton> */}
      <ProductImage 
        src='https://firebasestorage.googleapis.com/v0/b/iclean-website.appspot.com/o/iClean%20Products%2F7a627c85-ad75-461d-9569-a83012635464.png?alt=media&token=f6bf587e-089e-4f08-a351-d9d0769d06f0'
        alt="iClean Bleaching Powder" 
      />
      <SectionTitle>Features & Benefits</SectionTitle>
      <FeaturesContainer>
        <Feature>
          <FeatureIcon><FaSprayCan /></FeatureIcon>
          <FeatureText>Highly effective bleaching agent</FeatureText>
        </Feature>
        <Feature>
          <FeatureIcon><FaRegSmile /></FeatureIcon>
          <FeatureText>Eliminates germs and bacteria</FeatureText>
        </Feature>
        <Feature>
          <FeatureIcon><FaShieldAlt /></FeatureIcon>
          <FeatureText>Safe for various surfaces</FeatureText>
        </Feature>
        <Feature>
          <FeatureIcon><FaLeaf /></FeatureIcon>
          <FeatureText>Eco-friendly and non-toxic</FeatureText>
        </Feature>
      </FeaturesContainer>
      <SectionTitle>Customer Testimonials</SectionTitle>
      <TestimonialsContainer>
        <Testimonial>
          <TestimonialText>"iClean Bleaching Powder works wonders! My floors are spotless."</TestimonialText>
          <Stars>
            <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
          </Stars>
        </Testimonial>
        <Testimonial>
          <TestimonialText>"Best bleaching powder I've used. Highly recommend!"</TestimonialText>
          <Stars>
            <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
          </Stars>
        </Testimonial>
        <Testimonial>
          <TestimonialText>"Excellent product with great results."</TestimonialText>
          <Stars>
            <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
          </Stars>
        </Testimonial>
      </TestimonialsContainer>
      <SectionTitle>How to Use</SectionTitle>
      <Instructions>
        <p>1. Sprinkle directly or Dilute with water as per the requirement.</p>
        <p>2. Apply to the desired surface.</p>
        <p>3. Leave it for a few minutes.</p>
        <p>4. Scrub and rinse with water.</p>
      </Instructions>
      <FAQContainer>
        <FAQTitle>Frequently Asked Questions</FAQTitle>
        <p>1. How often should I use iClean Bleaching Powder?</p>
        <p>2. Is it safe for all surfaces?</p>
        <p>3. Can it be used for laundry?</p>
      </FAQContainer>
      <CTAButton to="/products">More Products</CTAButton>  {/* Adjust the 'to' prop based on your routing */}
    </PageContainer>
  );
};

export default BleachingPowderPage;
