import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  padding: 20px;
  margin: 20px;
  text-align: center;
  background: rgba(0,0,0,0 0.4);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  color: #333;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }

  img {
    border-radius: 15px;
    margin-bottom: 15px;
  }

  h2 {
    font-size: 1.5rem;
    margin: 10px 0;
    font-family: 'League Spartan', sans-serif;
    color: rgba(0, 0, 0, 0.8);
  }

  p {
    font-size: 1rem;
    margin: 5px 0;
    font-family: 'Arial', sans-serif;
   color: rgba(0, 0, 0, 0.8);

    &:last-child {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.8);
    }
  }
`;

const ProductCard = ({ product, onClick }) => {
  return (
    <Card onClick={onClick}>
      <img src={product.image} alt={product.name} width="100%" />
      <h2>{product.name}</h2>
      {/* <p>{product.description}</p> */}
      {/* <p>MRP : ₹{product.price}</p> */}
    </Card>
  );
};

export default ProductCard;
