import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ProductCard from './ProductCard';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
`;

const GlassProductCard = styled(ProductCard)`
  background-color: rgba(255, 255, 255, 0.1); /* Glass background color */
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1); /* Glass effect shadow */
  border-radius: 12px; /* Rounded corners */
  padding: 20px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px); /* Lift card on hover */
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
  }

  h3 {
    margin-top: 0;
    font-size: 1.5rem;
    color: #333;
  }

  p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 10px;
  }

  .price {
    font-size: 1.25rem;
    font-weight: bold;
    color: #1a73e8; /* Accent color for price */
  }
`;

const products = [
  { id: 1, name: 'Floor Cleaner', description: 'Floor Cleaner 500ml', price: 67, image: 'https://firebasestorage.googleapis.com/v0/b/iclean-website.appspot.com/o/iClean%20Products%2FIMG_1606.PNG?alt=media&token=5e30c980-3a41-4422-bf02-c31a46e7dbff' },
  { id: 2, name: 'Muriatic Acid', description: 'Muriatic Acid 500ml', price: 59, image: 'https://firebasestorage.googleapis.com/v0/b/iclean-website.appspot.com/o/iClean%20Products%2FIMG_1609.PNG?alt=media&token=c7e0cbdd-0601-4f0e-8fb9-058e48384bb9' },
  { id: 3, name: 'Toilet Cleaner', description: 'Toilet Cleaner 500ml', price: 75, image: 'https://firebasestorage.googleapis.com/v0/b/iclean-website.appspot.com/o/iClean%20Products%2FIMG_1608.PNG?alt=media&token=3572e8c0-2752-4c79-817a-c93ee89f32c1' },
  // { id: 4, name: 'Metal Cleaner', description: 'Toilet Cleaner', price: 35, image: 'https://firebasestorage.googleapis.com/v0/b/iclean-website.appspot.com/o/iClean%20Products%2FIMG_1655.PNG?alt=media&token=c8de7985-651a-4466-881e-b9b76710152b' },
  { id: 4, name: 'Bleaching Powder', description: 'Toilet Cleaner', price: 50, image: 'https://firebasestorage.googleapis.com/v0/b/iclean-website.appspot.com/o/iClean%20Products%2F7a627c85-ad75-461d-9569-a83012635464.png?alt=media&token=f6bf587e-089e-4f08-a351-d9d0769d06f0' },

  // Add more products here
];

const ProductList = () => {
  const navigate = useNavigate();

  const handleProductClick = (product) => {
    if (product.name === 'Floor Cleaner') {
      navigate('/floorcleanerpage');
    }
  
    if (product.name === 'Toilet Cleaner') {
      navigate('/toiletcleanerpage');
    }
    if (product.name === 'Bleaching Powder') {
      navigate('/bleachingpowderpage');
    }
    if (product.name === 'Muriatic Acid') {
      navigate('/muriaticacidpage');
    }
    // Add more product-specific navigation here if needed

    
  };

  return (
    <Grid>
      {products.map((product) => (
        <GlassProductCard
          key={product.id}
          product={product}
          onClick={() => handleProductClick(product)}
        >
          <h3>{product.name}</h3>
          <p>{product.description}</p>
          <div className="price">${product.price}</div>
        </GlassProductCard>
      ))}
    </Grid>
  );
};

export default ProductList;
