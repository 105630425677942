import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Odometer from 'react-odometerjs';
import { TbPackages } from 'react-icons/tb';
import { FaStore } from "react-icons/fa";

import 'odometer/themes/odometer-theme-default.css';
import founderImage from '../images/founder.jpeg';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AboutContainer = styled.div`
  padding: 40px 20px;
  background: rgba(255, 255, 255, 0.4); 
  backdrop-filter: blur(10px); 
  -webkit-backdrop-filter: blur(10px); 
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  color: #111;
  max-width: 800px;
  margin: 40px auto;
  text-align: left;
  animation: ${fadeIn} 1s ease-out;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: linear-gradient(45deg, rgba(255, 87, 34, 1), rgba(0, 0, 0, 0.8)); /* Orange to red gradient with opacity */
  text-align: center;
  font-family: 'League Spartan', sans-serif;
  
`;

const Subtitle = styled.h2`
  font-size: 1.75rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: linear-gradient(45deg, rgba(255, 87, 34, 1), rgba(0, 0, 0, 0.8)); /* Orange to red gradient with opacity */
  text-align: center;
  font-family: 'League Spartan', sans-serif;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  text-align: center;
  font-family: 'League Spartan', sans-serif;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const FounderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
`;

const FounderImage = styled.img`
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const FounderDetails = styled.div`
  text-align: center;
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  text-align: center;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatIcon = styled.div`
  font-size: 4.5rem;
  margin-bottom: 0.5rem;
  color: ${props => props.color || '#000'};
`;

const StatLabel = styled.p`
  font-size: 1rem;
  margin-top: 0.5rem;
  color: #888;
`;

const CitiesContainer = styled.div`
  margin-top: 2rem;
  text-align: center;
`;

const CitiesGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 1.5rem;
`;

const CityCard = styled.div`
  padding: 10px 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'League Spartan', sans-serif;
  font-size: 1rem;
  color: #333;
  min-width: 120px;
  text-align: center;
`;

const About = () => {
  const [downloadCount, setDownloadCount] = useState(0);
  const [sellerCount, setSellerCount] = useState(0);

  useEffect(() => {
    setTimeout(() => setDownloadCount(10000), 1000);
    setTimeout(() => setSellerCount(100), 1500);
  }, []);

  const cities = ["ULUBERIA", "AMTA", "BAGNAN", "BAURIA", "SHYAMPUR"];

  return (
    <AboutContainer>
      <Title>About Us</Title>
      <Paragraph>
        iClean is a leading manufacturer of cleaning products.
        <br />
        Our mission is to provide high-quality cleaning solutions
        <br />
        to make your life easier and healthier.
      </Paragraph>

      <StatsContainer>
        <StatItem>
          <StatIcon color="#4CAF50"><TbPackages /></StatIcon>
          <Odometer value={downloadCount} format="d" duration={2000} />
          <StatLabel>Unit Sold +</StatLabel>
        </StatItem>
        <StatItem>
          <StatIcon color="#2196F3"><FaStore /></StatIcon>
          <Odometer value={sellerCount} format="d" duration={2000} />
          <StatLabel>Seller +</StatLabel>
        </StatItem>
      </StatsContainer>

      <Subtitle>Company Details</Subtitle>
      <Paragraph>
        <BoldText>Address:</BoldText> Uluberia, Howrah 711316
      </Paragraph>
      <FounderContainer>
        <FounderImage src={founderImage} alt="Founder Image" />
        <FounderDetails>
          <Paragraph>
            <BoldText>Founder:</BoldText> Mohd Sohal Farhad
          </Paragraph>
          <Paragraph>
            <BoldText>UDYAM REGISTRATION NUMBER:</BoldText> UDYAM-WB-08-0057436
          </Paragraph>
          <Paragraph>
            <BoldText>Certification:</BoldText> ISO 9001:2015 Certified
          </Paragraph>
        </FounderDetails>
      </FounderContainer>

      <Subtitle>We Are Available At</Subtitle>
      <CitiesContainer>
        <Paragraph>We are currently serving in areas of West Bengal!</Paragraph>
        <CitiesGrid>
          {cities.map((city, index) => (
            <CityCard key={index}>{city}</CityCard>
          ))}
        </CitiesGrid>
      </CitiesContainer>
    </AboutContainer>
  );
};

export default About;
