import React from 'react';
import { ClipLoader } from 'react-spinners'; // Import a spinner from react-spinners
import styled from 'styled-components';

const SpinnerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  z-index: 9999;
`;

const LoadingSpinner = () => (
  <SpinnerWrapper>
    <ClipLoader color="#111" size={50} /> {/* Adjust size and color as needed */}
  </SpinnerWrapper>
);

export default LoadingSpinner;
