import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  padding: 20px;
  color: #ffffff;
  text-align: center;
   margin-top: auto;
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  border-radius: 10px; /* Optional: rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow */

`;

const Footer = () => {
  return <FooterContainer>© 2024 iClean. All rights reserved.</FooterContainer>;
};

export default Footer;
