import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  color: black;
  position: relative;
  z-index: 10;

   
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  border-radius: 10px; /* Optional: rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow */
  padding: 10px; /* Optional: padding */
`;

const NavLinks = styled.div`
  display: flex;
  & > a {
    margin: 0 15px;
    color: black;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s;
    &:hover {
      color: #fe2902;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align:right;
    background: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  border-radius: 10px; /* Optional: rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow */
    position: absolute;
    padding: 10px 25px;
    top: 60px;
    left: 0;
    right: 0;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    transition: max-height 0.3s ease-in-out;
    max-height: ${({ isOpen }) => (isOpen ? '200px' : '0')};
    overflow: hidden;

    & > a {
      margin: 10px 0; /* Increased space between links for mobile view */
    }
  }
`;

const Burger = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 25px;
  height: 20px;
  cursor: pointer;
  padding: 5px;

  @media (max-width: 768px) {
    display: flex;
  }

  div {
    height: 2px;
    background: black;
    border-radius: 2px;
    transition: all 0.3s;
    m
  }

  &:hover div {
    background: #fe2902;
  }
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #fe2902;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: black;
  }
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Navbar>
      <Logo>iClean</Logo>
      <Burger onClick={toggleMenu}>
        <div />
        <div />
        <div />
      </Burger>
      <NavLinks isOpen={isOpen}>
        <Link to="/" onClick={toggleMenu}>Home</Link>
        <Link to="/products" onClick={toggleMenu}>Products</Link>
        <Link to="/about" onClick={toggleMenu}>About</Link>
        <Link to="/contact" onClick={toggleMenu}>Contact</Link>
      </NavLinks>
    </Navbar>
  );
};

export default Header;
