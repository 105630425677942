import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Products from './pages/Products';
import FloorCleanerPage from './pages/subpages/FloorCleanerPage';
import ToiletCleanerPage from './pages/subpages/ToiletCleanerPage';
import BleachingPowderPage from './pages/subpages/BleachingPowderPage';
import MuriaticAcidPage from './pages/subpages/MuriaticAcidPage';
import About from './pages/About';
import Contact from './pages/Contact';
import GlobalStyle from './styles';
import LoadingSpinner from './components/LoadingSpinner';
import './App.css';

const App = () => {
  const [loading, setLoading] = useState(true);
  const vantaRef = useRef(null);
  const vantaEffect = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    const loadVanta = () => {
      if (vantaEffect.current) return;
      if (window.VANTA && window.VANTA.FOG) {
        vantaEffect.current = window.VANTA.FOG({
          el: vantaRef.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.00,
          minWidth: 200.00,
        });
      }
    };

    const threeScript = document.createElement('script');
    threeScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/three.js/r134/three.min.js';
    threeScript.async = true;
    threeScript.onload = () => {
      const vantaScript = document.createElement('script');
      vantaScript.src = 'https://cdn.jsdelivr.net/npm/vanta/dist/vanta.fog.min.js';
      vantaScript.async = true;
      vantaScript.onload = loadVanta;
      document.body.appendChild(vantaScript);
    };
    document.body.appendChild(threeScript);

    return () => {
      clearTimeout(timer);
      if (vantaEffect.current) vantaEffect.current.destroy();
    };
  }, []);

  return (
    <>
      {loading && <LoadingSpinner />}
      <div className="App">
        <Router>
          <GlobalStyle />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/floorcleanerpage" element={<FloorCleanerPage />} />
            <Route path="/toiletcleanerpage" element={<ToiletCleanerPage />} />
            <Route path="/bleachingpowderpage" element={<BleachingPowderPage />} />
            <Route path="/muriaticacidpage" element={<MuriaticAcidPage />} />
          </Routes>
          <Footer />
        </Router>
        <div className="background" ref={vantaRef}></div>
      </div>
    </>
  );
};

export default App;
